// main.ts
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/common.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "./http";

export {};

declare global {
  interface Window {
    SITE_CONFIG: string;
  }
}

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// app.config.globalProperties.$axios = axios;
// app.use(axios);
// window.axios = axios;
app.use(ElementPlus);
app.use(store);
app.use(router);

app.mount("#app");
