import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

export const Layout = () => import("@/views/layout/layout-index.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    meta: { hidden: true },
  },
  {
    path: "/",
    name: "/",
    component: Layout,
    redirect: "/homepage",
    children: [
      {
        path: "homepage",
        name: "homepage",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/homepage.vue"),
        meta: {
          title: "欢迎来到12380举报网站",
          name: "网站首页",
        },
      },
      {
        path: "notice",
        name: "notice",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/notice.vue"),
        meta: { title: "举报须知", name: "举报须知" },
      },
      {
        path: "introduce",
        name: "introduce",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/introduce.vue"),
        meta: { title: "受理介绍", name: "受理介绍" },
      },
      {
        path: "principle",
        name: "principle",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/principle.vue"),
        meta: { title: "法规制度", name: "法规制度" },
      },
      {
        path: "advice",
        name: "advice",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/advice.vue"),
        meta: { title: "意见建议", name: "意见建议" },
      },
      {
        path: "addetail",
        name: "addetail",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/addetail.vue"),
        meta: { title: "欢迎来到12380举报网站", name: "详情" },
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
});
// 把路由信息存到vuex中
router.beforeEach((to, from, next) => {
  // 提交mutation
  store.commit("setRoutes", routes);
  console.log(document.title);
  document.title = to.meta.title as string;
  next();
});
export default router;
