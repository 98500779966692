import { createStore } from "vuex";

export default createStore({
  state: {
    //   路由信息
    routes: [],
    //   举报须知点击事件
    clickNotice: "",
  },
  getters: {
    getRoutes(state) {
      return state.routes;
    },
    getClickNotice(state) {
      return state.clickNotice;
    },
  },
  mutations: {
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setClickNotice(state, clickNotice) {
      state.clickNotice = clickNotice;
    },
  },
  actions: {},
  modules: {},
});
